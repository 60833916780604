import logo from "./logo.svg";
import "./App.css";
import UpdateSale from "./components/UpdateSale";
import img from "./images/rcclogo.png";
import pos from "./images/textpos.png";
function App() {
  return (
    <div className="App">
      <div className="logo">
        <img src={img} />
      </div>
      <UpdateSale />
      <div className="desc">
        <h5>This is where the sale text will show on the website.</h5>
        <div className="pos">
          <img src={pos} width="50%" />
        </div>
        <div className="warning">
          <h5>
            ***Warning: This will update on your site immediately. Please ensure
            correct spelling and verbage before submitting.{" "}
          </h5>
        </div>
      </div>
    </div>
  );
}

export default App;
