import React, { useState, useEffect } from "react";
import axios from "axios";
import "../App.css";

const UpdateSale = () => {
  const [saleText, setSaleText] = useState("");
  const theCode = "ridecitysale";
  const [code, setCode] = useState("");
  const handleSubmit = (event) => {
    console.log(saleText);
    const updatedSale = {
      id: 1,
      text: saleText,
    };
    if (code === theCode) {
      axios.post(
        "https://us-central1-rcc-sale.cloudfunctions.net/app/sale/update",
        updatedSale
      );
      alert("Your change has been made.");
    } else {
      alert("code was incorrect, please try again");
    }

    event.preventDefault();
  };

  return (
    <div className="update-sale">
      <h1>Update your flash sale here.</h1>
      <div className="update-form">
        <form onSubmit={handleSubmit}>
          <div className="code">
            <h5>Enter code provided to you by BTWG</h5>
            <label>
              Code:{" "}
              <input
                type="text"
                name="code"
                onChange={(e) => setCode(e.target.value)}
              />
            </label>
          </div>
          <div className="text">
            <h5>Enter Sale text that will show at the top of your website.</h5>

            <label>
              Text:{" "}
              <input
                type="text"
                name="text"
                onChange={(e) => setSaleText(e.target.value)}
              />
            </label>
          </div>
          <div className="submit">
            <button
              className="btn btn-md btn-success"
              type="submit"
              value="Submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateSale;
